import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Channels from './pages/Channels';
import { isLoggedIn } from './utils/auth';

function App() {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setLoggedIn(!!token);
  }, [Navigate]);

  return (
    <Router>
      <Routes>
        <Route path="/" element={loggedIn ? <Navigate to="/channels" /> : <Login setLoggedIn={setLoggedIn} />} />
        <Route path="/channels" element={loggedIn ? <Channels /> : <Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
