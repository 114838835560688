import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Button } from 'react-bootstrap';
import { useNavigate, Navigate } from 'react-router-dom';
import { getWalletAddress, isLoggedIn } from '../utils/auth';
import PlayerWithLogo from '../player/PlayerWithLogo'; // Ensure the correct import path
import MpegtsPlayer from '../player/MpegtsPlayer'; // Ensure the correct import path
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Channels.css'; // Import the CSS file

const Channels = () => {
  const [channels, setChannels] = useState([]);
  const [currentUrl, setCurrentUrl] = useState('');
  const navigate = useNavigate();
  //const walletAddress = getWalletAddress();
  const isLogin = isLoggedIn()

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate('/');
    } else if (isLogin) {
      const fetchChannels = async () => {
        const token = localStorage.getItem('token');
        try {
          const response = await axios.get('https://api.solympiad.fun/channels', {
            headers: { Authorization: `Bearer ${token}` },
          })
          setChannels(response.data);
        } catch (error) {
          if (error.response && error.response.status === 403){
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            navigate('/')
          }
        }
      };
      fetchChannels();
    }
  }, [navigate, isLogin]);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
      slidesToSlide: 3 // optional, default to 1
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      slidesToSlide: 2 // optional, default to 1
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1
    }
  };

  return (
    <Container className="mt-5">
      <h2 className="mb-4">Available Channels</h2>
      {isLogin ? (
        <>
          <Carousel responsive={responsive} className="carousel-container">
            {channels.map((channel) => (
              <Button
                key={channel._id}
                className="mb-1 w-100"
                variant="primary"
                onClick={() => setCurrentUrl(channel.link)} // Assuming each channel has a URL property
              >
                {channel.name}
              </Button>
            ))}
          </Carousel>
          {currentUrl && currentUrl.includes("m3u8") ? (
            <div className="mt-4">
              <PlayerWithLogo url={currentUrl} />
              {/*<MpegtsPlayer url={currentUrl} />*/}
            </div>
          ) : currentUrl && (
            <MpegtsPlayer url={currentUrl} />
          )}
        </>
      ) : (
        <Navigate to="/" />
      )}
    </Container>
  );
};

export default Channels;
