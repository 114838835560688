export const isLoggedIn = () => {
  const token = localStorage.getItem('token');
  return !!token;
};

export const getWalletAddress = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  return user ? user.walletAddress : null;
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('walletAddress');
};