import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Card, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import Clipboard from 'clipboard';

const Login = ({ setLoggedIn }) => {
  const [userData, setUserData] = useState(null);
  const [message, setMessage] = useState('');
  const [verificationAddress, setVerificationAddress] = useState('');
  const [verificationHolding, setVerificationHolding] = useState('');
  const [tokenConfig, setTokenConfig] = useState(null);
  const [copySuccess, setCopySuccess] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const copyButtonRef = useRef(null);

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    try {
      tg.ready();

      // Fetch user data from Telegram Web App
      const user = tg.initDataUnsafe.user;
      //console.log('Telegram User Data:', user); // Log user data for debugging
      if (user) {
        setUserData(user);
        if (!localStorage.getItem('token')) {
          checkUser(user.id);
        } else {
          navigate('/channels');
        }
      } else {
        console.log('User data not available');
      }
    } catch (error) {
      console.error('Error initializing Telegram WebApp:', error);
    }
  }, []);

  useEffect(() => {
    if (copyButtonRef.current) {
      const clipboard = new Clipboard(copyButtonRef.current);

      clipboard.on('success', () => {
        setCopySuccess('Copied!');
        setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
      });

      clipboard.on('error', () => {
        setCopySuccess('Failed to copy!');
        setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
      });

      return () => clipboard.destroy();
    }
  }, [verificationAddress]);

  const checkUser = async (tgUserID) => {
    try {
      const response = await axios.post('https://api.solympiad.fun/users/', { tgUserID });
      console.log('Check User Response:', response.data); // Log response for debugging
      if (response.data.token) {
        // Save the token and wallet address in local storage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        setLoggedIn(true);
        navigate('/channels'); // Redirect to channels after login
        return;
      }

      const tokenConfigResponse = await axios.get('https://api.solympiad.fun/tokens');
      //console.log('Token Config Response:', tokenConfigResponse.data); // Log token config for debugging
      setTokenConfig(tokenConfigResponse.data);

      if (response.data.message.includes('You need to send negligible SOL amount')) {
        setVerificationAddress(response.data.verificationAddress);
      }

      if (response.data.message.includes('You should be holding more')) {
        setVerificationHolding(response.data.amount);
      }

      setMessage(response.data.message);
    } catch (error) {
      console.error('Error checking user:', error.response ? error.response.data : error.message);
    }
  };

  const verifyWallet = async () => {
    try {
      setIsButtonDisabled(true);
      const response = await axios.post('https://api.solympiad.fun/users/checkwallet', { tgUserID: userData.id });
      console.log('Verify Wallet Response:', response.data); // Log response for debugging
      setMessage(response.data.message);
      if (response.data.message === 'Wallet address verified') {
        setLoggedIn(true);
        navigate('/channels');
      }
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 10000); // Enable button after 10 seconds
    } catch (error) {
      console.error('Error verifying wallet:', error.response ? error.response.data : error.message);
      setMessage(error.response ? error.response.data.message : 'Error verifying wallet');
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 10000); // Enable button after 10 seconds
    }
  };

  if (!userData) {
    return <div>Loading user data...</div>;
  }

  return (
    <Container className="d-flex align-items-center justify-content-center" style={{ height: '100vh' }}>
      <Card style={{ width: '400px' }}>
        <Card.Body>
          <h2 className="text-center mb-4">Hello, {userData.first_name}!</h2>
          {/*<p className="text-center">{message}</p>*/}
          {verificationAddress && !verificationHolding && (
            <>
              <p className="text-center" ref={copyButtonRef} data-clipboard-text={verificationAddress}>
                You need to send negligible SOL amount to <strong>{verificationAddress}</strong>
                <FontAwesomeIcon icon={faCopy} style={{ marginLeft: '5px', marginRight: '5px', cursor: 'pointer' }} />
                to verify your wallet address
              </p>
              <Button className="w-100 mt-4" onClick={checkUser(userData.id)} disabled={isButtonDisabled}>
                Verify Now
              </Button>
            </>
          )}
          {!verificationAddress && verificationHolding && (
            <>
              <p className="text-center" ref={copyButtonRef} data-clipboard-text={verificationHolding}>
                You should be holding more than <strong>{verificationHolding}</strong> of $Solympiad to watch LIVE olympic games !
              </p>
              <Button className="w-100 mt-4" onClick={verifyWallet} disabled={isButtonDisabled}>
                Verify Now
              </Button>
            </>
          )}
          {/*copySuccess && <Alert variant="success">{copySuccess}</Alert>*/}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default Login;
