import React, { useEffect, useRef } from 'react';
import mpegts from 'mpegts.js';

const MpegtsPlayer = ({ url }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const setupPlayer = () => {
      try {
        if (mpegts.getFeatureList().mseLivePlayback) {
          const player = mpegts.createPlayer({
            type: 'mpegts',
            url: url,
            isLive: true,
          });

          player.attachMediaElement(videoRef.current);
          player.load();

          player.on(mpegts.Events.ERROR, (errorType, details, error) => {
            console.error('Error in player:', errorType, details, error);
            alert(`Error in player: ${errorType}, Details: ${details}, Error: ${error ? error.message : 'undefined'}`);
          });

          return () => {
            player.destroy();
          };
        } else {
          console.error('MPEG-TS live playback is not supported in this browser.');
          alert('MPEG-TS live playback is not supported in this browser.');
        }
      } catch (error) {
        console.error('Error setting up the player:', error);
        alert(`Error setting up the player: ${error.message}`);
      }
    };

    setupPlayer();
  }, [url]);

  return (
    <div>
      <video ref={videoRef} muted autoPlay controls width="100%" />
    </div>
  );
};

export default MpegtsPlayer;
