import React from 'react';
import ReactPlayer from 'react-player';
import './PlayerWithLogo.css'; // Ensure the correct path

const PlayerWithLogo = ({ url }) => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        playing={true}
        controls={true}
        width="100%"
        height="100%"
      />
      <img src="https://i.ibb.co/LNVGYgz/logo.png" alt="Logo" className="player-logo" />
    </div>
  );
};

export default PlayerWithLogo;
